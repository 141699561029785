import type { ResponseWrapper } from '@/api/types'
import { GLOBAL_API_ERROR } from '@/constants/keys'
import auth from '@/lib/auth/oAuth'
import { errorBridge } from '@/lib/error-bridge-state'
import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import axios from 'axios'
import type { DeferredPromise } from 'p-defer'
import pDefer from 'p-defer'

const URL = process.env.PUBLIC_LOLOYAL_API_URL

const client = axios.create({
  baseURL: URL,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
  },
})

// 对请求前的拦截器
client.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = auth.getToken()
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

// 对响应的拦截器
client.interceptors.response.use(
  (response: AxiosResponse<ResponseWrapper>) => {
    // if (response.config.url) {
    //   const { url } = response.config
    //   if (response.data.code === 1000) {
    //     bannerBridge.triggerRemove(url)
    //   }
    //   if (response.data.code === 1000007) {
    //     bannerBridge.triggerAdd(url, response.data.message || response.data.msg)
    //   }
    // }
    if (response.data?.code && response.data.code !== 200) {
      if (response.data.code > 500 && auth.state.authenticated) {
        // 已经在登录状态的情况下，token失效
        return retry(response.config)
      }
      return Promise.reject(response.data)
    }

    if (response.data.data && response.data.success) {
      response.data = response.data.data
    } else if (!response.data.data) return response
    else Promise.reject(response.data)

    return response
  },
  (error: any) => {
    errorBridge.show(
      GLOBAL_API_ERROR,
      error.message + ' in ' + error.config.url,
    )
    return Promise.reject(error)
  },
)

export default client

let authDeferredPromise: DeferredPromise<boolean> | null = null
async function retry(config: AxiosRequestConfig) {
  if (!authDeferredPromise) {
    authDeferredPromise = pDefer()
    auth.reAuth().then(() => {
      authDeferredPromise?.resolve(true)
      authDeferredPromise = null
    })
  }
  await authDeferredPromise.promise

  if (config.headers) {
    config.headers['Authorization'] = auth.getToken()
  }
  return client(config)
}
