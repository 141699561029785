import { getShopNameKey } from '@/lib/storage-scheme'

// 无痕模式的情况下，使用该storage模拟sessionStorage
let memoryStorage: Record<string, any> = {}
const shopNameKey = getShopNameKey()
const store = {
  setItem(name: string, value: any, flag?: string) {
    memoryStorage[shopNameKey + name] = value
  },
  getItem(name: string, flag?: string) {
    return memoryStorage[shopNameKey + name]
  },
  removeItem(name: string, flag?: string) {
    delete memoryStorage[shopNameKey + name]
  },
  clear(flag?: string) {
    memoryStorage = {}
  },
}

export default store
