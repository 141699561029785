import memoryStorage from '@/lib/memory-storage'

export function getShopNameKey() {
  if (location.host === 'localhost:8888') {
    return ''
  } else {
    return (
      new URLSearchParams(window.location.search)
        .get('shop')
        ?.replace('.myshopify.com', '') + '-'
    )
  }
}
function getStorageFlag() {
  let storageFlag: string

  // 判断本地储存是否可用
  try {
    localStorage.setItem('storageFlag', 'local')
    sessionStorage.setItem('storageFlag', 'session')
    storageFlag =
      localStorage.getItem('storageFlag')! &&
      sessionStorage.getItem('storageFlag')!
    localStorage.removeItem('storageFlag')
    sessionStorage.removeItem('storageFlag')
  } catch (error) {
    storageFlag = 'memory'
  }
  return storageFlag
}
function getStorageScheme() {
  let storageScheme: typeof memoryStorage

  // 不可用则使用内存储存
  // 使用sessionStorage时需要穿一个参数用于判断
  // eg: storageScheme.setItem('key','value', 'session')
  if (storageFlag === 'memory') {
    storageScheme = memoryStorage
  } else {
    storageScheme = {
      setItem(name, value, flag) {
        if (flag === 'session')
          sessionStorage.setItem(shopNameKey + name, value)
        else localStorage.setItem(shopNameKey + name, value)
      },
      getItem(name, flag) {
        if (flag === 'session')
          return sessionStorage.getItem(shopNameKey + name)
        else return localStorage.getItem(shopNameKey + name)
      },
      removeItem(name, flag) {
        if (flag === 'session') sessionStorage.removeItem(shopNameKey + name)
        else localStorage.removeItem(shopNameKey + name)
      },
      clear(flag) {
        if (flag === 'session') sessionStorage.clear()
        else localStorage.clear()
      },
    }
  }

  return storageScheme
}

const shopNameKey = getShopNameKey()

const storageFlag = getStorageFlag()

const storageScheme = getStorageScheme()

export default storageScheme
