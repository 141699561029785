import storageScheme from '@/lib/storage-scheme'

const generateLoloyalKey = (s: string) => `@@loloyal-once-${s}`
const set = (k: string, v: any) =>
  storageScheme.setItem(generateLoloyalKey(k), v, 'session')
const get = (k: string) =>
  storageScheme.getItem(generateLoloyalKey(k), 'session')

export function tryGetState(keys: string[]) {
  let allExist = true
  let result: Record<string, any> = {}
  try {
    for (const key of keys) {
      const value = get(key)
      if (!value) {
        allExist = false
        break
      }
      result[key] = value
    }
  } catch (e) {
    allExist = false
  }
  return allExist ? result : null
}

export function keepState(state: Record<string, any>) {
  const keys = Object.keys(state)
  for (const key of keys) {
    set(key, state[key])
  }
}
