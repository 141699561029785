import { TOKEN_KEY } from '@/constants/keys';
import { keepState, tryGetState } from '@/lib/keepState';
import storageScheme from './storage-scheme';

const _done = {
  bool: false,
}

export const $env = {
  dashboard: false, // 后台
  dashboardToken: '', // 后台传的 token
  localhost: false,
  skipAppBridge: false,
  chargeId: '', // 订阅的订单id
}
;(function (env) {
  if (_done.bool) return
  const searchParams = new URLSearchParams(window.location.search)

  env.chargeId = searchParams.get('charge_id') ?? ''

  env.dashboard = searchParams.get('from_dashboard') === '√'

  env.dashboardToken = searchParams.get('from_dashboard_token') ?? ''

  env.localhost = window.location.hostname === 'localhost'

  env.skipAppBridge = env.localhost || env.dashboard

  if (env.chargeId) storageScheme.setItem('add-plan-charge-id', env.chargeId)

  if (!env.dashboard) {
    const lastDashboardState = tryGetState([
      'dashboard',
      'from_dashboard_token',
    ])
    if (lastDashboardState) {
      env.dashboard = lastDashboardState.dashboard
      env.dashboardToken = lastDashboardState.from_dashboard_token
    }
  }

  if (env.dashboard && env.dashboardToken) {
    storageScheme.setItem(TOKEN_KEY, env.dashboardToken)

    const shopName = searchParams.get('shop')
    if (shopName) {
      storageScheme.setItem('shopName', shopName)
    }

    keepState({
      dashboard: env.dashboard,
      from_dashboard_token: env.dashboardToken,
    })
  }

  _done.bool = true
})($env)
