import '@/lib/shopify-polyfill'

export const isEmbedded = () => {
  if (!window.shopify) return false
  return window.shopify.environment.embedded
}

interface ToastOptions {
  duration: number
  isError: boolean
  action: string
  onAction: () => void
  onDismiss: () => void
}
export const toast = {
  show: (message: string, opts?: Partial<ToastOptions>) => {
    if (isEmbedded() && shopify.toast) {
      if (!opts) {
        opts = {
          duration: 2000,
        }
      }
      return shopify.toast.show(message, opts)
    } else {
      return alert(message)
    }
  },
}
export const saveBar = {
  async hide(id: string) {
    if (isEmbedded() && shopify.saveBar) {
      return await shopify.saveBar.hide(id)
    } else {
      return true
    }
  },
}

const ShopifyBridge = {
  toast,
  saveBar,
}
export default ShopifyBridge
