import AuthApi from '@/api/auth'
import shopApi from '@/api/shop'
import { shopInfoAtom } from '@/atom/shop-info'
import { GLOBAL_API_ERROR, TOKEN_KEY } from '@/constants/keys'
import { setSnapshot } from '@/lib/auth/pubsub'
import { errorBridge } from '@/lib/error-bridge-state'
import { keepState, tryGetState } from '@/lib/keepState'
import { $env } from '@/lib/location-env'
import { isEmbedded } from '@/lib/shopify-bridge'
import storageScheme from '@/lib/storage-scheme'
import { getDefaultStore } from 'jotai'

class TokenExpiredError extends Error {
  code = '50X'
  message = 'Token Expired'
}

const oAuthState = {
  authenticated: false,
}
const openTarget = isEmbedded() ? '_top' : '_self'
const globalAtomStore = getDefaultStore()
const urlSearch = new URLSearchParams(location.search)
let hmac = urlSearch.get('hmac')
let shop = urlSearch.get('shop')
let timestamp = urlSearch.get('timestamp')
let host = urlSearch.get('host')

const shopifyAppQueryKeys = ['hmac', 'shop', 'timestamp', 'host']
function iframeKeepAlive() {
  if (!hmac) {
    const oldState = tryGetState(shopifyAppQueryKeys)
    if (oldState) {
      hmac = oldState.hmac
      shop = oldState.shop
      timestamp = oldState.timestamp
      host = oldState.host
    }
  } else {
    keepState({
      hmac,
      shop,
      timestamp,
      host,
    })
  }
}
iframeKeepAlive()

/**
 * @description 是否重新登录成功，否则需要重定向
 */
async function reAuth(): Promise<boolean> {
  if (!shop) return false
  storageScheme.setItem('shopName', shop)

  const res = await AuthApi.authUser({
    hmac,
    shop,
    timestamp,
    app_id: Number(process.env.PUBLIC_LOLOYAL_APP_ID),
  })

  // 上报authUser接口返回的关键信息
  const data = res.data

  storageScheme.setItem('authUserInfo', JSON.stringify(data))

  if (data.status === 1) {
    // 重定向去安装
    open(data.url, openTarget)
    return false
  }

  if (data.status === 2) {
    // 进入APP
    storageScheme.setItem(TOKEN_KEY, res.data.token)
    // 新手引导完成状态
    storageScheme.setItem('completedGuide', data.completed_guide, 'session')

    // 判断本页面是否由code为500的请求跳转而来的，如果是，则获取完信息之后，跳转回去
    const nowRoute = storageScheme.getItem('nowRoute')
    if (nowRoute) {
      storageScheme.removeItem('nowRoute')
      open(nowRoute, openTarget)
      return false
    }

    // 判断本页面是否由用户从书签进入（无token直接进入页面）
    const firstRoute = storageScheme.getItem('firstRoute')
    if (firstRoute) {
      storageScheme.removeItem('firstRoute')
      open(firstRoute, openTarget)
      return false
    }
    //  else {
    //   open('/', '_top')
    // }

    // 如果不是嵌入模式，直接跳转到对应的页面
    if (!isEmbedded() && (host || shop)) {
      open(
        `https://${host ? atob(host) : shop + '/admin'}/apps/${
          process.env.PUBLIC_SHOPIFY_API_KEY
        }`,
        openTarget,
      )
      return false
    }
    return true
  }

  if (data.status === 3) {
    // 权限不足需要重装
    open(data.url, openTarget)
    return false
  }

  return false
}

/**
 * 是否已经登录
 */
async function checkAuth(): Promise<boolean> {
  if ($env.localhost) {
    storageScheme.setItem(TOKEN_KEY, shop ?? 'sub-test001.myshopify.com')
    const done = await getShopData()
    return done
  }

  if ($env.dashboard) {
    storageScheme.setItem(TOKEN_KEY, $env.dashboardToken)
    const done = await getShopData()
    return done
  }

  const shopToken = storageScheme.getItem(TOKEN_KEY)

  // NewUser
  if (
    !shopToken ||
    checkJWTExpired(shopToken) ||
    (shop && hmac && timestamp && !isEmbedded())
  ) {
    const reAuthDone = await reAuth()
    if (!reAuthDone) return reAuthDone
  }

  // OldUser
  try {
    const done = await getShopData()
    return done
  } catch (error) {
    if (!(error instanceof TokenExpiredError)) {
      throw error
    }

    // Token过期
    const authDone = await reAuth()
    if (!authDone) return authDone

    const done = await getShopData()
    return done
  }
}

export async function getShopData() {
  let shopInfo = globalAtomStore.get(shopInfoAtom)
  if (!shopInfo) {
    try {
      const res = await shopApi.getShopData()
      globalAtomStore.set(shopInfoAtom, res.data)
    } catch (error: any) {
      if (error.code && error.code > 500) {
        throw new TokenExpiredError()
      }
      throw error
    }
  }
  return true
}

function getToken() {
  if ($env.dashboard) {
    return storageScheme.getItem(TOKEN_KEY) || storageScheme.getItem('shopName')
  }
  return storageScheme.getItem(TOKEN_KEY)
}

async function init() {
  await checkAuth()
    .then((done) => {
      if (done) {
        setSnapshot(true)
      }
    })
    .catch((error) => {
      errorBridge.show(GLOBAL_API_ERROR, error.message)
      setSnapshot(true)
    })
}

const checkJWTExpired = (token: string) => {
  if (!token) return true
  const currentTime = new Date().getTime() / 1000 + 3000
  const payloadString = token.split('.')[1]
  try {
    const expTime = JSON.parse(atob(payloadString)).exp
    const expired = currentTime > expTime
    return expired
  } catch (error) {
    return true
  }
}

export default {
  init,
  getToken,
  checkAuth,
  reAuth,
  state: oAuthState,
}
