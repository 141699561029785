import { produce } from 'immer'
import { atom } from 'jotai'

type VipTier = {
  goal: string
  vip_name: string
}

export interface UpdateVipParamsType {
  status: number
  mile_stone: number
  start_date: number
  entry_method: number
  granting_mechanism: number
  distribution_method: number
}

export interface VipProgram extends UpdateVipParamsType {
  last_vip_data: VipTier[]
  vip_name_list: VipTier[]
}

export type UserInfo = {
  id: number
  shop_id: number
  shop_name: string
  language: string
  member: number
  completed_guide: string
  status: number
  created_at: number
  updated_at: number
  is_delete: number
  point_name: string
  customer_account: number
  iana_timezone: string
  show_theme_notice: string
  theme_template_id: string
  domain: string
  phone: string
  username: string
  email: string
  time_zone_r: string
  country_name: string
  address1: string
  address2: string
  plans: number
  finished_steps: string
  hide_getting_started: number
  plan_display_name: string
  review_rating: number
  name: string
  valid_order_price: number
  points_cancel: number
  reward_channel: number
  moneyFormat: string
  free_plan_id: number
  free_order_limit: number
  currency: string
  feedback_status: number
  points_expire_status: number
  points_expire_time: number
  filter_data: null | any // You may replace 'any' with a specific type if needed
  vip: VipProgram
  participants: number
  sort: string
  visible_brandmark: number
  subscribe_coupon_status: number
  discount_prefix_setting: {
    mode: number
    has_prefix: number
    prefix: {
      global: string
      loyalty_reward: string
      vip_tier_reward: string
      referral_friend_reward: string
      referral_advocate_reward: string
      limited_time_offers_reward: string
    }
  }
}

type PlanBenefit = {
  value: boolean
  apply: string
}

export type PlanUserBenefit = Record<string, PlanBenefit>

export type PlanType = {
  plan: Plan
  user_benefit: PlanUserBenefit
  plan_usage_amount: PlanUsageAmount
  downgrade_plan: DowngradePlan
}

export type Plan = {
  id: number
  display_name: string
  description: string
  currency: string
  trial: number
  is_free: number
  sort: number
  annual_price: string
  annual_origin_price: string
  monthly_price: string
  monthly_origin_price: string
  sub_expire_time: number
  interval: string
  sub_start_time: number
  sub_amount: string
  is_early_bird: boolean
  is_easter_eggs: boolean
  order_cycle_start: number
  order_cycle_end: number
}

export type PlanUsageAmount = {
  order_limit: number
  order_expire_time: number
}

export type DowngradePlan = {
  id: number
  pid: number
  name: string
  display_name: string
  description: string
  currency: string
  monthly_price: string
  monthly_origin_price: string
  annual_origin_price: string
  annual_price: string
  discount_monthly: number
  discount_annual: number
  trial: number
  is_free: number
  sort: number
  is_display: number
  created_at: string
  updated_at: string
  is_delete: number
  is_basic: number
  order_limit: number
}

type EarlyBirdCountdown = number

export type IconResource = { id: number; icon_url: string }
export type IconResourceMap = {
  amount_coupon: IconResource[]
  percent_coupon: IconResource[]
  points_reward: IconResource[]
  shipping_coupon: IconResource[]
  product_coupon: IconResource[]
  ways_to_earn: IconResource[]
  ways_to_redeem: IconResource[]
  celebrate_a_birthday: IconResource[]
  email: IconResource[]
  facebook: IconResource[]
  ins: IconResource[]
  place_an_order: IconResource[]
  post_a_product_review: IconResource[]
  sign_up: IconResource[]
  tiktok: IconResource[]
  twitter: IconResource[]
  youtube: IconResource[]
  visit_website: IconResource[]
  member_signups: IconResource[]
  points_engagement: IconResource[]
  reward_engagement: IconResource[]
}

type CannyToken = string

export type Data = {
  userInfo: UserInfo
  plan: PlanType
  early_bird_countdown: EarlyBirdCountdown
  cannyToken: CannyToken
  icon_resource: IconResourceMap
  first_entry: number
  completed_guide: number
  trial_plan: { id: number; status: number; countdown: number }
  is_show_footer: number
  home_feedback_status: boolean
}

export const shopInfoAtom = atom<Data | undefined>(undefined)

export const showFooterAtom = atom((get) => {
  const shopInfo = get(shopInfoAtom)
  return shopInfo?.is_show_footer || false
})

export const moneyFormatAtom = atom((get) => {
  const shopInfo = get(shopInfoAtom)
  return shopInfo ? shopInfo.userInfo.moneyFormat : ''
})

export const currencySymbolAtom = moneyFormatAtom

export const userInfoAtom = atom(
  (get) => {
    const shopInfo = get(shopInfoAtom)
    return (shopInfo?.userInfo as UserInfo) || {}
  },
  (get, set, update: UserInfo) => {
    const shopInfo = get(shopInfoAtom)
    if (shopInfo) {
      set(
        shopInfoAtom,
        produce(shopInfo, (draft) => {
          draft.userInfo = update
        }),
      )
    }
  },
)

export const vipInfoAtom = atom(
  (get) => {
    const shopInfo = get(shopInfoAtom)
    return shopInfo?.userInfo.vip as VipProgram
  },
  (get, set, update: VipProgram) => {
    const shopInfo = get(shopInfoAtom)
    if (shopInfo) {
      set(
        shopInfoAtom,
        produce(shopInfo, (draft) => {
          draft.userInfo.vip = update
        }),
      )
    }
  },
)

export const planInfoAtom = atom(
  (get) => {
    const shopInfo = get(shopInfoAtom)
    return shopInfo ? shopInfo.plan.plan : ({} as Plan)
  },
  (get, set, update: Plan) => {
    const shopInfo = get(shopInfoAtom)
    if (shopInfo) {
      set(
        shopInfoAtom,
        produce(shopInfo, (draft) => {
          draft.plan.plan = update
        }),
      )
    }
  },
)

export const iconResourceAtom = atom((get) => {
  const shopInfo = get(shopInfoAtom)
  return shopInfo?.icon_resource || null
})

export const isShowFooterAtom = atom((get) => {
  const shopInfo = get(shopInfoAtom)
  return shopInfo?.is_show_footer || 0
})

export const isCompletedGuideAtom = atom((get) => {
  const shopInfo = get(shopInfoAtom)
  return Number(shopInfo?.completed_guide)
})
