import { produce } from 'immer'
import { atom, getDefaultStore } from 'jotai'

export const errorStackAtom = atom<Record<string, string>>({})

// error pub sub
export const errorBridge = {
  show(scope: string, error: string) {
    getDefaultStore().set(
      errorStackAtom,
      produce((draft) => {
        draft[scope] = error
      }),
    )
  },
  hide(scope: string) {
    getDefaultStore().set(
      errorStackAtom,
      produce((draft) => {
        draft[scope] = ''
      }),
    )
  },
}
